export const includesFilterFunction = (
    rows: any,
    id: number,
    filterValue: string[],
) => {
    return rows?.filter((row: any) => filterValue?.includes(row.values[id]));
};

export const toNumber = (value: any) => {
    return Number(value?.toString()?.replace(/\D/g, ''));
};

export const toLocale = (value: string | number) => {
    return toNumber(value)?.toLocaleString('pl');
};

export const findMax = (data: any, key: string) => {
    const numbers = data?.map((item: any) => {
        return Number(item[key]);
    });
    return numbers && toLocale(Math?.ceil(Math?.max(...numbers)));
};

export const customFormatNumber = (value: number) => {
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};

export const findMin = (data: any, key: string) => {
    const numbers = data?.map((item: any) => {
        return Number(item[key]);
    });
    return numbers && toLocale(Math?.floor(Math?.min(...numbers)));
};

export const findOptions = (data: any, key: string) => {
    const options = data?.map((item: any) => item[key]);
    return [...new Set(options)] as [];
};
