import React from 'react';

interface SvgProps {
    size?: number;
}

export const FilePDF = ({ size }: SvgProps) => {
    return (
        <svg
            width={size ? size : 24}
            height={size ? size : 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                fill="white"
            />
            <path
                d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                stroke="#466BA2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                fill="white"
                stroke="#466BA2"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
